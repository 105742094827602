import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { HTMLContent } from '../components/Content'
import Layout from '../components/Layout'
import BlogPostTemplate from './BlogPostTemplate'

const BlogPost = ({ data }) => {
  const {
    markdownRemark: {
      html,
      frontmatter: { description, title, tags }
    }
  } = data

  return (
    <Layout title={title} description={description}>
      <BlogPostTemplate
        content={html}
        contentComponent={HTMLContent}
        description={description}
        tags={tags}
        title={title}
      />
    </Layout>
  )
}

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object
  })
}

export default BlogPost

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        tags
      }
    }
  }
`
